<template>
  <el-dialog :visible.sync="dialog" title="上报" append-to-body :close-on-click-modal="false" :show-close="!doing" :close-on-press-escape="false" @closed="handleClosed" width="1080px">
    <div class="fc-e lh-150" style="margin-bottom: 20px;">
      以下为准备上报的销售单据列表，其中标有底色的单据为还没有支付完全款或还有支付单未到账的单据。
      <br />开始上报后无法中止，确定上报请点击“开始上报”，上报过程可能耗费较长时间，请耐心等候。
    </div>
    <el-table :data="queues" :row-class-name="checkWarning" border>
      <el-table-column prop="formCode" label="销售单号" min-width="130" />
      <el-table-column prop="shopName" label="门店" min-width="180" show-overflow-tooltip />
      <el-table-column prop="realTotalMoney" label="总金额" min-width="100" align="right" :formatter="$price" />
      <el-table-column label="已收款" min-width="100" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{$price(scope.row.payAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditAmount" label="已审收款" min-width="100" align="right" :formatter="$price" />
      <el-table-column label="待收款" min-width="100" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount < scope.row.realTotalMoney ? 'fc-e' : ''">{{$price(scope.row.realTotalMoney-scope.row.payAmount)}}</span>
        </template>
      </el-table-column>

      <el-table-column label="供应来源" width="140" v-if="$erp.enable()">
        <template slot-scope="scope">
          <quick-select v-model="scope.row.supplySource" :options="$refs.sssel ? $refs.sssel.store : []" auto-select-first-option clearable style="width: 100%" />
        </template>
      </el-table-column>

      <el-table-column label="自动审核" width="80" v-if="$erp.enable()">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.needApprove" />
        </template>
      </el-table-column>

      <el-table-column key="progress" width="100" label="进度">
        <template slot-scope="scope">
          <div class="fc-e" v-if="scope.row._error">上报失败</div>
          <div class="fc-g" v-else-if="scope.row._cancelled">已取消</div>
          <div class="fc-wa" v-else-if="scope.row._loading">
            <i class="el-icon-loading"></i>&nbsp;上报中…
          </div>
          <div class="fc-s" v-else-if="scope.row._reported">已上报</div>
          <div class="fc-g" v-else>待上报</div>
        </template>
      </el-table-column>
    </el-table>

    <div class="h c gap-1x" v-if="$erp.enable()">
      <div class="flex ta-r">统一设置：</div>
      <div class="padding-0-10" style="width: 140px">
        <quick-select ref="sssel" v-model="unify.supplySource" url="api/sale/supplySource" auto-select-first-option clearable style="width: 100%;" />
      </div>
      <div class="padding-0-10" style="width: 80px">
        <el-switch v-model="unify.needApprove" />
      </div>
      <div class="padding-0-10" style="width: 100px">
        <el-button type="primary" @click="doUnifySet">应用</el-button>
      </div>
    </div>

    <div class="h c sb" style="margin-top: 24px" v-if="!doing && finished + failed + cancelled > 0">
      <div class="h c">
        上报完成，其中成功
        <b class="fc-s">&nbsp;{{finished}}&nbsp;</b>个，取消
        <b class="fc-g">&nbsp;{{cancelled}}&nbsp;</b>个，失败
        <b class="fc-e">&nbsp;{{failed}}&nbsp;</b>个。
      </div>
      <el-button type="primary" v-if="!doing" @click="dialog = false">关闭</el-button>
    </div>
    <div slot="footer" v-else>
      <el-button type="text" v-if="!doing" @click="dialog = false">取消</el-button>
      <el-button type="primary" :loading="doing" @click="start(0)">开始上报</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sendNotice } from "@/api/shopOrder";

export default {
  data() {
    return {
      queues: null,
      current: -1,
      doing: false,
      dialog: false,
      finished: 0,
      failed: 0,
      cancelled: 0,
      hasChanged: false,
      unify: {
        supplySource: null,
        needApprove: false,
      },
    };
  },
  methods: {
    handleClosed() {
      if (this.hasChanged) {
        this.$parent.init();
      }
      this.queues = null;
      this.doing = false;
      this.finished = 0;
      this.failed = 0;
      this.cancelled = 0;
      this.hasChanged = false;
    },
    checkWarning({ row, inx }) {
      return row.auditAmount < row.realTotalMoney ? "el-table-warning-row" : "";
    },
    set(orders) {
      if (orders && orders.length) {
        this.queues = orders.map((o) => {
          return Object.assign({}, o, {
            _loading: false,
            _reported: false,
            _error: false,
            _cancelled: false,
            supplySource: null,
            needApprove: false,
          });
        });
        this.current = -1;
        this.dialog = true;
      }
    },
    start(inx) {
      if (inx >= 0 && inx < this.queues.length) {
        this.hasChanged = true;
        this.current = inx;
        this.doing = true;
        let order = this.queues[this.current];
        if (!order._loading) {
          if (order.auditAmount >= order.realTotalMoney) {
            this.doReport(order);
          } else {
            this.$confirm(
              `销售单【${order.formCode}】还没有付完全款或者还有支付单未到账，仍然要进行上报吗？`,
              "上报提示",
              {
                confirmButtonText: "仍然上报",
                cancelButtonText: "暂不上报",
                type: "warning",
              }
            )
              .then((_) => {
                this.doReport(order);
              })
              .catch((_) => {
                order._cancelled = true;
                this.cancelled++;
                this.start(this.current + 1);
              });
          }
        }
      } else {
        this.$notify({
          title: "上报结束",
          type: "success",
          duration: 2500,
        });
        this.doing = false;
      }
    },
    doUnifySet() {
      this.queues.forEach((q) => {
        Object.assign(q, this.unify);
      });
    },
    doReport(order) {
      order._loading = true;
      sendNotice({
        id: order.id,
        supplySource: order.supplySource,
        needApprove: order.needApprove
      })
        .then((res) => {
          order._reported = true;
          this.finished++;
        })
        .catch((e) => {
          order._error = true;
          this.failed++;
        })
        .finally((_) => {
          order._loading = false;
          this.start(this.current + 1);
        });
    },
  },
};
</script>